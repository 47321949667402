<template>
  <div class="max-w-screen-mobile mx-auto h-screen  py-2 relative">
    <OnboardingHeader progress="width: 50%" :btnText="$t('onboarding.header')" />
    <div class="px-6 pt-4 pb-3.5">
      <div class="h-40 rounded-lg py-10 bg-gradient-to-b from-halfColonialWhite-transparent30 to-halfColonialWhite-transparent text-center">GIF/Video/IMG</div>
      <p class="font-body text-bodyS text-redDamask tracking-midWide font-medium">{{ $t('onboarding.3') }}</p>
      <h4 class="font-heading text-h4 dark:text-white text-sepiaBlack">{{ $t('onboarding.3title') }}</h4>
      <p class="font-body text-bodyL dark:text-white text-sepiaBlack leading-normal">{{ $t('onboarding.3text') }}</p>
    </div>
    <OnboardingFooter prev="Onboarding2" next="Onboarding4"/>
  </div>
</template>

<script>

import OnboardingHeader from '@/components/mobile/OnboardingHeader.vue';
import OnboardingFooter from '@/components/mobile/OnboardingFooter.vue';

export default {
  name: 'Onboard1',
  components: {
    OnboardingHeader,
    OnboardingFooter
  },
  mounted() {
    this.$i18n.locale = this.lang;
  },
  data() {
    return {
      lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
    }
  }
}
</script>