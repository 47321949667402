<template>
    <div class="h-screen w-tv-main-axis mx-auto text-center relative" v-on:click="click" >
        <Header />
        <div class="hidden mt-96 mx-auto" id="errorM">
            <SystemMessage :text= response?.error type='error' />
        </div>
        <div class="hidden mt-96 mx-auto" id="successM">
            <h1 class="font-heading text-h2 text-redDamask">{{ $t('timeline.successMessage', {input: response?.user_name }) }}</h1>
            <SystemMessage :text= response?.text type='success' class="mt-2"/>
        </div>
        <div class="absolute top-2/5 left-4.5/10 -ml-20">
            <h1 class="font-heading text-h1 text-redDamask">{{ $t('timeline.timelineTitle') }}</h1>
            <p class="font-body text-bodyXL text-sepiaBlack dark:text-halfColonialWhite mb-4">{{ $t('timeline.timelineSubtitle') }}</p>
            <PinCodeInput @pinChange="pinChanged" />
            <div class="flex justify-center gap-2 mt-10">
                <Button buttonType='secondary' :text="$t('timeline.backBtn')" :disabledBtn="btnDisabel" @click="this.$router.go(-1)"/>
                <Button buttonType='primary' :text="$t('timeline.saveBtn')" :disabledBtn="btnDisabel" v-on:click="saveToTimeline()"/>
            </div>
        </div>
    </div>
</template>

<script>

import Header from "@/components/Header.vue";
import Button from '@/components/Button.vue';
import PinCodeInput from "@/components/tablet/PinCodeInput.vue";
import SystemMessage from '@/components/SystemMessage.vue';

export default {
    name: "Tablet",
    components: {
        Header,
        Button,
        PinCodeInput,
        SystemMessage
    },
    data(){
        return {
            baseURL: process.env.VUE_APP_AXIOS_URL,
            id: this.$route.params.id,
            pinInput: '',
            response: {},
            seconds: 60,
			standard_sec: 60,
            device: this.$route.name == 'TVPin' ? 'tv' : 'tablet',
            btnDisabel: false,
        }
    },
    mounted() {
        const authUser = localStorage.getItem('token') ? JSON.parse(window.atob(localStorage.getItem('token').split('.')[1])) : null;
  		// if (!authUser) {
        	this.startTimer();
		// }
    },
    created () {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        redirect() {
            this.$router.push('/' + this.device + 's?category=' +localStorage.getItem(this.device + '_category') + '&seconds=60');
        },
        saveToTimeline() {
            this.btnDisabel = true;
            const search_input = this.pinInput;
            console.log(search_input);
            this.axios.post(this.baseURL + `/wp-json/wp/v2/timelinebypin`, {entity_id: this.id, pin: search_input, language: 'en'}, {})
            .then(response => {
                const response_data = response.data;
                this.response = response.data;
                if (response_data.error) {
                    // alert(response_data.error);
                    document.querySelector("#errorM").classList.remove("hidden");
                    this.btnDisabel = false;
                } else {
                    console.log(response);
                    document.querySelector("#successM").classList.remove("hidden");
                    document.querySelector("#errorM").classList.add("hidden");
                    setTimeout(this.redirect, 3000);
                    this.btnDisabel = false;
                }
            }).catch( (error) => {
                console.log(error);
                this.btnDisabel = false;
            });
            // alert(this.$route.params.id + ` saved to timeline`);
            // const authUser = localStorage.getItem('token') ? JSON.parse(window.atob(localStorage.getItem('token').split('.')[1])) : null
            // this.axios.post(this.baseURL + `/wp-json/wp/v2/timelinebyuser`, {entity_id: this.entity_id, user_id: authUser.data.user.id, language: 'en'}, {})
            // .then(response => {
            //     const response_data = response.data;
            //     if (response_data.error) {
            //         console.log(response);
            //     } else {
            //         console.log(response);
            //     }
            // }).catch( (error) => {
            //     console.log(error);
            // });
        },
        pinChanged(value) {
            this.pinInput = value;
            if (this.seconds > 0) {
                this.seconds = this.standard_sec;
            }
        },
        startTimer() {
            this.seconds = this.standard_sec;
            setTimeout(this.countdown, 1000);
        },
        countdown() {
            console.log(this.seconds);
            if (this.seconds > 0) {
                this.seconds -= 1;
                setTimeout(this.countdown, 1000);
            } else {
				this.$router.push('/' + this.device + 's?category=' +localStorage.getItem(this.device + '_category') + '&seconds=0');
			}
        },
        click() {
            if (this.seconds > 0) {
                this.seconds = this.standard_sec;
            }
        },
        handleScroll (event) {
            if (this.seconds > 0) {
                this.seconds = this.standard_sec;
            }
        }, 
    },
};
</script>
